import React from 'react'
import Button from 'react-bootstrap/Button';
import { Link } from "react-router-dom";
import { BsFacebook } from 'react-icons/bs';
import { BsInstagram } from 'react-icons/bs';
import { BsWhatsapp } from 'react-icons/bs';
import { FaPhoneVolume} from 'react-icons/fa';
import { MdOutlineMail } from 'react-icons/md';
import { BiSolidPaperPlane } from 'react-icons/bi';
import {FaPlaneDeparture} from 'react-icons/fa';
import ReactWhatsapp from 'react-whatsapp';
import{GiSharkFin} from 'react-icons/gi'
import "../css/Footer.css"
const Footer = () => {
  return (
    <div className="footer1">
        <div className="Footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-lg-5 col-12 ft-1">
                            <h3><FaPlaneDeparture />  G&E<span> for international shipping</span></h3>
                            <p>International Shipping company.</p>
                            <div className="footer-icons">
                                <i><BsFacebook/></i>
                                <i><BsInstagram/></i>
                                <i><ReactWhatsapp number='+2  01097911820' message='start!'> <BsWhatsapp/></ReactWhatsapp> </i>

                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 col-12 ft-2">
                            <h5>Quick Links</h5>
                            <ul>
                                <li className="nav-item">
                                    <Link className='nav-link' to={"/"}>Home</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className='nav-link' to={"/about"}>About Us</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-6 col-lg-4 col-12 ft-3">
                            <h5>Contact Us</h5>
                            <p><i><FaPhoneVolume/></i>01063364244</p>
                            <p><i><FaPhoneVolume/></i> 01097911820</p>
                            <p><i><MdOutlineMail/></i>info@G&Eshipping.com</p>
                            <p><i><BiSolidPaperPlane/></i>المريوطية ، فيصل ، الجيزة </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='footer-bottom'>
            All Copyright &copy; reserved to G&E Shipping
        </div>
    </div>
  )
}

export default Footer;
