import {Navigate, createBrowserRouter} from "react-router-dom";
import Home from "./components/pages/Home/Home";
import App from "./App";
import Aboutus from "./components/pages/Aboutus";


export const routes = createBrowserRouter([
    {
      path:'',
      element:<App/>,
      children :[
        {
            path: "/",
            element: <Home/>,
          },
          {
            path: "About",
            element: <Aboutus/>,
          },
          
        ]
    },
    {
        path:"*",
        element:<Navigate to={"/"} />
    }
    
   
  ]);
  