import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from "react-router-dom";
import ReactWhatsapp from 'react-whatsapp';
import {FaPeopleGroup} from 'react-icons/fa6'
import {BiSolidHome}from 'react-icons/bi'
import {FaPlaneDeparture} from 'react-icons/fa';  
import { BsFillTelephoneFill} from 'react-icons/bs';
import "../css/Header.css"
function Header() {
  return (
    <div>
      <Navbar bg="#008080" data-bs-theme="#008080" className='navbar'>
        <Container className='barr'>
          <Navbar.Brand>
            <Link className='nav-link' to={"/"} style={{color:"#fff" ,fontSize:"35px"}}><FaPlaneDeparture/>
              <span style={{color:"#DF2935"}}> G&E</span> shipping</Link><p className='fis'>for international shipping</p>
          </Navbar.Brand>
          
          <Nav className="ml-auto">
            {/* <Link className='nav-link1' to={"/"} style={{color:"#fff",fontSize:"20px"}}><BiSolidHome/>  الرئسية</Link> */}
            <Link className='nav-link' to={"/About"} style={{color:"#fff",fontSize:"20px"}}><FaPeopleGroup/>   من نحن</Link>
            <button className='btn-call1' style={{background:"#DF2935",borderRadius:"20px"}}>
              <ReactWhatsapp number='+2  01063364244' message='start!' className='nav-link' style={{color:"#fff"}}>
                تواصل معانا   <  BsFillTelephoneFill/></ReactWhatsapp>
            </button>
          </Nav>
        </Container>
        
      </Navbar>
    </div>
    
  )
}

export default Header