import logo from './logo.svg';
import './App.css';
import Header from './components/shared/Header';
import Footer from './components/shared/Footer';
import { Outlet } from 'react-router-dom';
import Header2 from './components/shared/Header2';
function App() {
  return (
    <div className="App">
      <Header2/>
      <Header/>
      <Outlet/>
      <Footer/>
    </div>
  );
}

export default App;
