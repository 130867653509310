import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from "react-router-dom";
import { FaLocationDot } from 'react-icons/fa6';
import { BsFillTelephoneFill } from 'react-icons/bs';
import {MdEmail} from 'react-icons/md'
import ReactWhatsapp from 'react-whatsapp';
import "../css/Header2.css"
function Header2() {
  return (
    <div className='opo'>
      <Navbar  className='navbar1'>
        <Container className='mm'>
          <Nav className="me-auto">
            <Link className='nav-link' to={""} style={{color:"black"}}><FaLocationDot/>  المريوطية ، فيصل ، الجيزة</Link>
            <ReactWhatsapp number='+2  01097911820' message='start!' className='nav-link' style={{color:"black"}}><BsFillTelephoneFill/>  +2  01063364244</ReactWhatsapp>
            <Link className='nav-link' to={""} style={{color:"black"}}><MdEmail/>  info@G&Eshipping.com</Link>
          </Nav>
        </Container>
      </Navbar>
    </div>
  )
}

export default Header2