import React from 'react'
import { useState,useRef,useEffect } from 'react';
const Counter =({start =0 , end}) =>{
    const[value,setvalue] = useState(null)
    const ref =useRef(start);

    const Counter = end /200

    const count =()=>{
        if(ref.current <end ){
            const result = Math.ceil(ref.current+ Counter)
            if(result > end) return setvalue(end)
            setvalue(result)
            ref.current =result
        }
        setTimeout(count,100)
    }

    useEffect(()=>{
        let isMounted = true
        if(isMounted){
            count()
        }
        return ()=>{isMounted=false}
    },{end})
    return(
        <div className='container'>
            <h2>{value}</h2>
        </div>
    )
  
}
export default Counter;
