import image1 from "./sliderphoto/photo1.jpeg"
import image2 from "./sliderphoto/photo2.jpg"
import image3 from"./sliderphoto/photo3.jpg"
import image4 from "./sliderphoto/photo4.png"

export const sliderData = [
    {
      image: image1,
      heading: "International Shipping Services",
      desc: " To All Countries Of The World, Door To Door, At The Best Rates.",
    },
    {
      image: image2,
      heading: "Experience ",
      desc: "Is Our Keys",
    },
    {
      image: image3,
      heading: "Shipping",
      desc: "To Your Door",
    },
    {
      image: image4,
      heading: "Safety",
      desc: "Is Our Work",
    },
  ];