import React from 'react'
import '../css/Aboutus.css'
import company from '../photos/b4.jpg'
import {BiTimeFive ,BiDollar} from 'react-icons/bi'
import {AiFillSafetyCertificate} from 'react-icons/ai'
import {RiCustomerService2Fill} from 'react-icons/ri'
import {FaTruckLoading ,FaShippingFast,FaCubes} from 'react-icons/fa'
import { BsFillTelephoneFill ,BsFillHouseDoorFill ,BsTelephoneFill} from 'react-icons/bs';
import {MdEmail} from 'react-icons/md'
import { FaLocationDot } from 'react-icons/fa6';
import ReactWhatsapp from 'react-whatsapp';
import {GiTrophyCup,GiSharkFin} from 'react-icons/gi'
import {FaPlaneDeparture} from 'react-icons/fa';  
import Counter from './Counter'
import ScrollToTop from "react-scroll-to-top";
export default function Aboutus() {
  return (
    <div>
      <div className='cover'>
       About <span style={{color:"#DF2935"}}>US</span>
      </div>
      <div className='about-comp'>
      <div className='imgabout'>
            <img src={company } alt="slide" className="image" />
          </div>
          <div className='about'>
            <h5 className='title2'>about</h5>
            <span className='subtitle'>خبره اكثر من 25 عاما  _____</span>
            <h2  className='subtitle' style={{color:"#DF2935",textAlign:"center",fontSize:"30px"}}><span style={{color:"#00006A"}}>G & E</span> Shipping</h2>
            <p className='ppp'>
            تأسست شركة چي اي للشحن الدولي السريع في عام 2001 ، لدينا نخبة من الموظفين ذوي الخبرة والكفاءة العالية في مجال الشحن الدولي, تتمتع الشركه بسمعة طيبة في هذا المجال, وذلك لان لديها كادر ذو خبره وكفاءة عالية في مجال الشحن الدولي بجميع انواعه ؛ الشركه هدفها الاول هو إرضاء العميل وتقديم خدمة مميزة.</p>
            <h2  className='subtitle8' style={{color:"#DF2935",textAlign:"center",fontSize:"30px"}}><span style={{color:"#00006A"}}>G & E for </span>international Shipping</h2>
          </div>
        </div>
        
      <div className='feature'>
        <div className='tit1'>
            <h5 className='title1'>Features</h5>
            <span className='subtitle1'>افضل شركات الشحن الدولى _____</span>
            <h2  className='subtitle1' style={{color:"#DF2935",textAlign:"center",fontSize:"30px"}}><span style={{color:"#00006A"}}>G & E</span> Shipping</h2>
        </div>
        <div className='feature1'>
        <div className="container2">
          <div className='btn=icon'>
            <FaShippingFast className='icon' />
          </div>          
            <div className="text2">
              <h5 className='h-icon'>شحن سريع لباب البيت</h5>
              <p className='des-icon'>نقدم خدمه الشحن من الباب للباب فى اسرع وقت و باقل الاسعار</p>
            </div>
          </div>
          <div className="container2">
            <div className='btn=icon'>
              <BiDollar className='icon' />
            </div>          
              <div className="text2">
                <h5 className='h-icon'>المعاينه و تقدير السعر للشحنات</h5>
                <p className='des-icon'>نقدم خدمه معاينه الشحنات و تقدير السعر للعميل مجانا</p>
              </div>
          </div>
          <div className="container2">
            <div className='btn=icon'>
              <FaTruckLoading className='icon' />
            </div>          
              <div className="text2">
                <h5 className='h-icon'>تخزين الشحنات</h5>
                <p className='des-icon'>نقوم بنقل وتخزين الشحنات للعملاء بدون اي رسوم اضافيه تمهيدا لشحنها الى وجهتها المقصودة</p>
              </div>
          </div>
          <div className="container2">
            <div className='btn=icon'>
              <AiFillSafetyCertificate className='icon' />
            </div>          
              <div className="text2">
                <h5 className='h-icon'>تغليف الشحنات</h5>
                <p className='des-icon'>نقوم بتغليف الشحنات باحترافية عاليه مما يضمن للعملاء وصول الشحنات سليمه كما هى</p>
              </div>
          </div>
          <div className="container2">
            <div className='btn=icon'>
              <BiTimeFive className='icon' />
            </div>          
              <div className="text2">
                <h5 className='h-icon'>تتبع لحظى للشحنات</h5>
                <p className='des-icon'>نقدم خدمة تتبع الشحنات بشكل لحظى حتى وصلها لباب البيت</p>
              </div>
          </div>
          <div className="container2">
            <div className='btn=icon'>
              <RiCustomerService2Fill className='icon' />
            </div>          
              <div className="text2">
                <h5 className='h-icon'>خدمه عملاء على مدار ال 24 ساعه</h5>
                <p className='des-icon'>متواجدون على مدار اليوم لخدمه عملائنا و الرد على استفسارتهم</p>
              </div>
          </div>
          </div>
          </div>
          <div className='adver'>
            <FaPlaneDeparture  style={{width:"50%",height:"50%",color:"#fff"}} />
            <p className='adverTitle'>رائدين فى مجال الشحن الدولي لجميع دول العالم و باقل الاسعار 
            </p>
            <button className='btn-call'><ReactWhatsapp number='+2  01097911820' message='start!' className='nav-link' style={{color:"#fff"}}>
                    تواصل معانا الان    <  BsFillTelephoneFill/></ReactWhatsapp>
            </button>
          </div>
          <div className='tit1'>
            <h5 className='title1'>G&E Shipping </h5>
            <span className='subtitle1'>ماذا نفعل ____</span>
            <h2  className='subtitle1' style={{color:"#DF2935",textAlign:"center",fontSize:"30px"}}><span style={{color:"#00006A"}}>G & E</span> Shipping</h2>
            </div>
            <div className='feature1'>
            <div className="container2">
            <div className='btn=icon'>
              <FaCubes className='icon' />
            </div>          
              <div className="text2">
                <h5 className='h-icon'>خبره اكثر من </h5>
                <p><Counter start={0} end={25}/> عاما</p>
              </div>
          </div>
          <div className="container2">
            <div className='btn=icon'>
              <GiTrophyCup className='icon' />
            </div>          
              <div className="text2">
                <h5 className='h-icon'> شحنه تم تسليمها</h5>
                <p><Counter start={0} end={7800}/></p>
              </div>
          </div>
          <div className="container2">
            <div className='btn=icon'>
              <BsFillHouseDoorFill className='icon' />
            </div>          
              <div className="text2">
                <h5 className='h-icon'>توصيل الى باب المنزل</h5>
                <p><Counter start={0} end={24}/>ساعه</p>
              </div>
          </div>
          

          </div>

          <div className='tit1'>
            <h5 className='title1'>Location</h5>
            <span className='subtitle1'>شحنك من الباب لباب_____</span>
            <h2  className='subtitle1' style={{color:"#DF2935",textAlign:"center",fontSize:"30px"}}><span style={{color:"#00006A"}}>G & E </span>Shipping</h2>
            </div>
          <div className='location'>
            <div className='detail'>
              <div className='deta'>
                <h5><BiTimeFive/>ساعات العمل</h5>
                <p>السبت - الخميس : 9:00 – 6:00</p>
                <p>الجمعة :  اجازة</p> 
              </div>
              <div className='deta'>
                <h5><BsFillTelephoneFill/>تواصل معانا</h5>
                <p>
                <ReactWhatsapp number='+2  01063364244' message='start!' className='nav-link' 
                style={{color:"black"}}>+2  01063364244</ReactWhatsapp>
                </p>
                <p>
               +2 01097911820
                </p>
              </div>
              <div className='deta'>
                <h5><MdEmail/>البريد الالكترونى</h5>
                <p>
                info@G&Eshipping.com
                </p>
              </div>
              <div className='deta'>
                <h5><FaLocationDot />العنوان</h5>
                <p>
                المريوطية ، فيصل ، الجيزة
                </p>
              </div>
           
            </div>
            <div className='mom'>
            <div class="mapouter">
            <div class="gmap_canvas">
                <iframe class="gmap_iframe"
                       frameborder="0" scrolling="no"
                        marginheight="0" marginwidth="0"
                         src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=المؤيوطيه فيصل&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
                          </iframe><a href="https://gachanymph.com/">Gacha Nymph</a></div>
                          </div>
            </div>
          </div>
          <ScrollToTop smooth color='#fff' style={{backgroundColor:"#00006A"}} /> 
          <div>
          <ReactWhatsapp number='+2  01063364244' message='start!' className='nav-link'> 
          <img src="https://png.pngtree.com/png-vector/20221018/ourmid/pngtree-whatsapp-icon-png-image_6315990.png" alt="slide" className="image-icon" />
          </ReactWhatsapp>
          </div> 
          <div>
          <a href='tel:+2 01063364244' style={{textDecoration:"none" }}>
          <BsTelephoneFill className="image-icon1" />
          </a>
          </div> 
    </div>
  )
}
